import { Observable } from "rxjs";
import React from "react";
import { fromJS, List, OrderedMap } from "immutable";
import fp from "lodash/fp";
import {
  compose,
  createEventHandler,
  getContext,
  mapPropsStream,
} from "recompose";
import PropTypes from "prop-types";
import { IconButton } from "@material-ui/core";
import { connect } from "react-redux";
import { Link } from "react-router";
import { FilterList } from "@material-ui/icons";
import { isEqualData } from "../../helpers/DataUtils";
import { pipeStreams } from "../../helpers/StreamUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { toOrderFilter } from "../../helpers/OrderFilterMapper";
import { getUser } from "../../reducers/ProfileReducer";
import {
  CUSTOMER_ITEM_URL,
  ORDER_LIST_URL,
  ORDERS_PER_POSTCODES_BETA_URL,
} from "../../constants/AdminPathConstants";
import { getOrderList } from "../../api/admin/AdminOrderApi";
import AdminOrderFilterWrapper from "../../wrappers/admin/AdminOrderFilterWrapper";
import AdminOrderDetailsDialogWrapperV2 from "../../wrappers/admin/AdminOrderDetailsDialogWrapperV2";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import Redirect from "../../components/router/Redirect";
import OrderList from "../../components/orders-core/OrderList";
import { updateHash, updateQuery } from "../../../shared/helpers/UrlUtils";
import { hasRole } from "../../helpers/RoleUtils";

const ORDER_FILTER_DIALOG_HASH = "#OFDH";

const searchTypes = OrderedMap()
  .set("all", "Everything")
  .set("order_number", "Order Number");

const ROLE_TOOLS_VIEWER = "ROLE_TOOLS_VIEWER";

const enhancer = compose(
  connect(state => {
    const userRoles = getUser(state).get("roles") || [];

    return {
      isToolViewer: hasRole(userRoles, ROLE_TOOLS_VIEWER),
    };
  }),
  getContext({
    setLocation: PropTypes.func.isRequired,
    setLocationQuery: PropTypes.func.isRequired,
    replaceLocationHash: PropTypes.func.isRequired,
    replaceLocationQuery: PropTypes.func.isRequired,
    setLocationQueryFilter: PropTypes.func.isRequired,
  }),
  mapPropsStream(
    pipeStreams(
      propsStream => {
        const {
          handler: onRequestRefresh,
          stream: onRequestRefreshStream,
        } = createEventHandler();

        const filterStream = propsStream
          .pluck("location", "query")
          .map(
            fp.flow(toOrderFilter, (filter: DataListFilter) =>
              filter.setValue("is_uae", null),
            ),
          )
          .distinctUntilChanged(isEqualData);

        const searchResultStream = filterStream
          .switchMap((filter: DataListFilter) =>
            filter.getValue("search")
              ? getOrderList(filter)
                  .repeatWhen(() => onRequestRefreshStream)
                  .catch(error => Observable.of({ error }))
              : Observable.of({}),
          )
          .map(
            fp.flow(
              response => fromJS(response),
              response =>
                fromJS({
                  isLoading: response.get("pending", false),
                  total: response.getIn(["payload", "data", "total"], 0),
                  list: response.getIn(["payload", "data", "list"], List()),
                }),
            ),
          )
          .distinctUntilChanged(isEqualData);

        return propsStream
          .combineLatest(
            filterStream,
            searchResultStream,
            (props, filter, searchResult) => ({
              ...props,
              filter,
              onRequestRefresh,
              list: searchResult.get("list"),
              total: searchResult.get("total"),
              isLoading: searchResult.get("isLoading"),
            }),
          )
          .distinctUntilChanged(isEqualData);
      },
      propsStream => {
        const sideEffectsStream = Observable.merge(
          propsStream
            .map(props => ({
              replaceLocationQuery: props.replaceLocationQuery,
              order: props.list.size === 1 ? props.list.get(0, null) : null,
            }))
            .distinctUntilKeyChanged("order")
            .filter(props => props.order && props.order.get("id") > 0)
            .do(props =>
              props.replaceLocationQuery(
                fp.set(
                  props.order.get("version") === 2 ? "view_v2" : "view",
                  props.order.get("id"),
                ),
              ),
            ),
        )
          .mapTo(null)
          .startWith(null)
          .distinctUntilChanged();

        return propsStream.combineLatest(sideEffectsStream, fp.identity);
      },
    ),
  ),
);

AllocationOrderTrackingToolContainer.propTypes = {
  location: PropTypes.object,
  setLocationQuery: PropTypes.func,
  onRequestRefresh: PropTypes.func,
  setLocationQueryFilter: PropTypes.func,
  filter: PropTypes.instanceOf(DataListFilter),
  isLoading: PropTypes.bool,
  total: PropTypes.number,
  list: PropTypes.instanceOf(List),
  replaceLocationHash: PropTypes.func,
  setLocation: PropTypes.func,
  isToolViewer: PropTypes.bool,
};

function AllocationOrderTrackingToolContainer(props) {
  const { location } = props;

  return (
    <AdminAppLayout title="Order Tracking">
      <Redirect when={props.isToolViewer} to={ORDERS_PER_POSTCODES_BETA_URL} />

      <Redirect
        when={!searchTypes.has(location.query.search_type)}
        to={updateQuery(location, fp.set("search_type", "order_number"))}
      />

      <AdminOrderFilterWrapper
        filter={props.filter.withMutations((filter: DataListFilter) => {
          if (!location.query.custom) {
            filter.setValue("status", null);
          }
        })}
        onFilterChange={filter => {
          const customFilter = filter.setValueMap(
            filter.getValue("status")
              ? { custom: 1 }
              : {
                  custom: null,
                  status: props.filter.getValue("status"),
                  include_exception: props.filter.getValue("include_exception"),
                },
          );

          props.replaceLocationHash(null);

          props.setLocation(
            updateQuery(ORDER_LIST_URL, customFilter.getDefinedValues()),
          );
        }}
        open={location.hash === ORDER_FILTER_DIALOG_HASH}
        onRequestClose={() => props.replaceLocationHash(null)}
      />

      <AdminOrderDetailsDialogWrapperV2
        tab={location.query.view_tab}
        orderId={fp.toFinite(location.query.view_v2)}
        onTabChange={tab => props.setLocationQuery(fp.set("view_tab", tab))}
        onRequestClose={() =>
          props.setLocationQuery(fp.omit(["view", "view_v2", "view_tab"]))
        }
        onEditClick={() =>
          props.setLocationQuery(
            fp.flow(
              fp.unset("view"),
              fp.unset("view_v2"),
              fp.set("edit_v2", location.query.view_v2),
            ),
          )
        }
        createDriverSuggestHref={() =>
          props.setLocationQuery(fp.flow(fp.set("driver_view", true)))
        }
        location={location}
      />

      <OrderList
        maxSearchItems={1}
        filter={props.filter}
        searchTypes={searchTypes}
        onFilterChange={filter => props.setLocationQueryFilter(filter)}
        list={props.list}
        totalCount={props.total}
        isLoading={props.isLoading}
        createCustomerHref={id => CUSTOMER_ITEM_URL + id}
        createOrderHref={(id, version) =>
          updateQuery(location, fp.set(version === 2 ? "view_v2" : "view", id))
        }
        cardActionIcons={
          <div>
            <IconButton
              tooltip="Filter Orders"
              containerElement={
                <Link to={updateHash(location, ORDER_FILTER_DIALOG_HASH)} />
              }
            >
              <FilterList />
            </IconButton>
          </div>
        }
      />
    </AdminAppLayout>
  );
}

export default enhancer(AllocationOrderTrackingToolContainer);
