import { Observable } from "rxjs";
import React from "react";
import Immutable from "immutable";
import fp from "lodash/fp";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import AdminCustomerItemDetailsContainer from "./AdminCustomerItemDetailsContainer";
import { isEqualData } from "../../helpers/DataUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import { getMarketplace } from "../../api/shared/MarketplaceApi";
import AdminAppLayout from "../../components/admin/AdminAppLayout";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  mapPropsStream(propsStream => {
    const marketplaceIdStream = getMarketplace()
      .takeLast(1)
      .catch(() => Observable.of({}))
      .map(
        fp.flow(fp.get("payload.data"), fp.toPlainObject, Immutable.fromJS, x =>
          x.get("marketplace_id"),
        ),
      );

    return propsStream
      .combineLatest(marketplaceIdStream, (props, marketplaceId) => ({
        ...props,
        marketplaceId,
      }))
      .distinctUntilChanged(isEqualData);
  }),
);

AdminCustomerItem.propTypes = {
  params: PropTypes.object,
  getLocalisationMessage: PropTypes.func,
};

function AdminCustomerItem(props) {
  const { params, getLocalisationMessage } = props;

  return (
    <AdminAppLayout title={getLocalisationMessage("customers", "Customers")}>
      <AdminCustomerItemDetailsContainer params={params} />
    </AdminAppLayout>
  );
}

export default enhancer(AdminCustomerItem);
