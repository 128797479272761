import { Observable } from "rxjs";
import React from "react";
import { Map, fromJS, OrderedMap } from "immutable";
import fp from "lodash/fp";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Tabs from "../ui-core/Tabs";
import TabBadge from "../deprecated/TabBadge";
import { renderIf } from "../../helpers/HOCUtils";
import { isEqualData, isEqualDataKeys } from "../../helpers/DataUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { getMarketplaceId } from "../../reducers/MarketplaceReducer";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  ARRIVED,
  ON_HOLD,
  ACCEPTED,
  CANCELLED,
  COMPLETED,
  PICKED_UP,
  DISPATCHED,
  IN_TRANSIT,
  ON_HIS_WAY,
  UNASSIGNED,
  COD_NOT_READY,
  OFFICE_CLOSED,
  OUT_FOR_RETURN,
  PICKED_UP_AUTO,
  TO_BE_RETURNED,
  WRONG_SHIPMENT,
  DELIVERY_FAILED,
  DRIVER_REJECTED,
  LOST_OR_DAMAGED,
  OUT_FOR_DELIVERY,
  DELIVERY_REJECTED,
  INCOMPLETE_PARCEL,
  RETURNING_TO_ORIGIN,
  DELIVERY_SCHEDULED,
  RETURNED_TO_ORIGIN,
  ASSIGNED_TO_COURIER,
  IN_SORTING_FACILITY,
  FIRST_PICKUP_ATTEMPT,
  NO_TIME_FOR_DELIVERY,
  OTHER_PICKUP_ATTEMPT,
  OUT_OF_DELIVERY_AREA,
  PREPARED_FOR_TRANSIT,
  THIRD_PICKUP_ATTEMPT,
  BAD_RECIPIENT_ADDRESS,
  SECOND_PICKUP_ATTEMPT,
  FIRST_DELIVERY_ATTEMPT,
  IN_TRANSIT_TO_SUPPLIER,
  LOST_OR_DAMAGED_CLOSED,
  OTHER_DELIVERY_ATTEMPT,
  RECIPIENT_MOBILE_WRONG,
  RECIPIENT_NAME_MISSING,
  THIRD_DELIVERY_ATTEMPT,
  RECIPIENT_NOT_AVAILABLE,
  SECOND_DELIVERY_ATTEMPT,
  NO_CAPACITY_FOR_DELIVERY,
  FUTURE_DELIVERY_REQUESTED,
  ARRIVED_TO_DELIVERY_ADDRESS,
  BAD_WEATHER_DURING_DELIVERY,
  RECIPIENT_MOBILE_NO_RESPONSE,
  DESTROYED_ON_CUSTOMER_REQUEST,
  RECIPIENT_MOBILE_SWITCHED_OFF,
  ID_OR_DOCUMENT_REQUIRED_MISSING,
  COLLECTION_ARRANGED_OR_REQUESTED,
  DELIVERY_DELAY_DUE_TO_LATE_BOOKING,
  RECIPIENT_ADDRESS_CHANGE_REQUESTED,
  UNABLE_TO_ACCESS_RECIPIENT_PREMISES,
} from "../../constants/OrderStatusCodes";
import { SWISH_NOW_ID } from "../../../server/constants/MarketplaceId";

const baseFilter = fromJS({
  page: 0,
  size: 1,
  simple: true,
  order_by: null,
  order_by_direction: null,
});

const ALL_TAB = "All";
const TO_BE_PICKED_UP_TAB = "To Be Picked Up";
const TO_BE_DELIVERED_TAB = "To Be Delivered";
const LOSTS_OR_DAMAGES_TAB = "Losts & Damages";
const DELIVERED_TAB = "Delivered";
const TO_BE_RETURNED_TAB = "To Be Returned";
const RTOS_TAB = "RTOs";
const CANCELLED_TAB = "Cancelled";

export const TO_BE_PICKED_UP_TAB_FILTERS = fromJS({
  page: 0,
  include_exception: false,
  status: [
    OTHER_PICKUP_ATTEMPT,
    UNASSIGNED,
    ASSIGNED_TO_COURIER,
    ACCEPTED,
    ON_HIS_WAY,
    ARRIVED,
    FIRST_PICKUP_ATTEMPT,
    SECOND_PICKUP_ATTEMPT,
    THIRD_PICKUP_ATTEMPT,
    DRIVER_REJECTED,
  ],
});

const tabBaseFilters = OrderedMap()
  .set(ALL_TAB, fromJS({ page: 0, include_exception: null, status: null }))
  .set(TO_BE_PICKED_UP_TAB, TO_BE_PICKED_UP_TAB_FILTERS)
  .set(
    TO_BE_DELIVERED_TAB,
    fromJS({
      page: 0,
      include_exception: false,
      status: [
        OTHER_DELIVERY_ATTEMPT,
        PICKED_UP_AUTO,
        PICKED_UP,
        IN_SORTING_FACILITY,
        IN_TRANSIT,
        OUT_FOR_DELIVERY,
        ARRIVED_TO_DELIVERY_ADDRESS,
        ON_HOLD,
        FIRST_DELIVERY_ATTEMPT,
        SECOND_DELIVERY_ATTEMPT,
        THIRD_DELIVERY_ATTEMPT,
        BAD_RECIPIENT_ADDRESS,
        RECIPIENT_NOT_AVAILABLE,
        RECIPIENT_MOBILE_SWITCHED_OFF,
        RECIPIENT_MOBILE_WRONG,
        RECIPIENT_MOBILE_NO_RESPONSE,
        RECIPIENT_ADDRESS_CHANGE_REQUESTED,
        COD_NOT_READY,
        FUTURE_DELIVERY_REQUESTED,
        OUT_OF_DELIVERY_AREA,
        UNABLE_TO_ACCESS_RECIPIENT_PREMISES,
        NO_CAPACITY_FOR_DELIVERY,
        ID_OR_DOCUMENT_REQUIRED_MISSING,
        BAD_WEATHER_DURING_DELIVERY,
        RECIPIENT_NAME_MISSING,
        COLLECTION_ARRANGED_OR_REQUESTED,
        WRONG_SHIPMENT,
        INCOMPLETE_PARCEL,
        DELIVERY_DELAY_DUE_TO_LATE_BOOKING,
        NO_TIME_FOR_DELIVERY,
        DELIVERY_REJECTED,
        DELIVERY_SCHEDULED,
        DELIVERY_FAILED,
        DISPATCHED,
        IN_TRANSIT_TO_SUPPLIER,
        PREPARED_FOR_TRANSIT,
        OFFICE_CLOSED,
      ],
    }),
  )
  .set(
    LOSTS_OR_DAMAGES_TAB,
    fromJS({
      page: 0,
      include_exception: false,
      status: [LOST_OR_DAMAGED, LOST_OR_DAMAGED_CLOSED],
    }),
  )
  .set(
    DELIVERED_TAB,
    fromJS({
      page: 0,
      include_exception: false,
      status: [COMPLETED],
    }),
  )
  .set(
    TO_BE_RETURNED_TAB,
    fromJS({
      page: 0,
      include_exception: false,
      status: [RETURNING_TO_ORIGIN, TO_BE_RETURNED, OUT_FOR_RETURN],
    }),
  )
  .set(
    RTOS_TAB,
    fromJS({
      page: 0,
      include_exception: false,
      status: [DESTROYED_ON_CUSTOMER_REQUEST, RETURNED_TO_ORIGIN],
    }),
  )
  .set(
    CANCELLED_TAB,
    fromJS({
      page: 0,
      include_exception: false,
      status: [CANCELLED],
    }),
  );

const enhancer = compose(
  connect((state) => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
    marketplaceId: getMarketplaceId(state),
  })),
  withRouter,
  mapPropsStream((propsStream) =>
    propsStream
      .distinctUntilChanged(isEqualDataKeys(["filter", "basePathname"]))
      .map((props) => {
        const customTabBaseFilters =
          props.marketplaceId === SWISH_NOW_ID
            ? tabBaseFilters
                .remove(LOSTS_OR_DAMAGES_TAB)
                .remove(TO_BE_RETURNED_TAB)
            : tabBaseFilters;
        const filters = customTabBaseFilters.map((x) =>
          props.filter.setValueMap(x),
        );

        return {
          filters,
          batchGetOrderListCount: props.batchGetOrderListCount,

          value: props.router.createHref({
            pathname: props.basePathname,
            query: props.filter.setPage(0).getDefinedValues(),
          }),

          tabs: filters.map((filter, label) => {
            const value = props.router.createHref({
              pathname: props.basePathname,
              query: filter.getDefinedValues(),
            });
            const localisedLabel = props.getLocalisationMessage(label) || label;

            return Map({ label: localisedLabel, value });
          }),
        };
      })
      .distinctUntilChanged(isEqualData),
  ),
  renderIf((props) => props.tabs.find((x) => props.value === x.get("value"))),
  mapPropsStream((propsStream) => {
    const tabCountsStream = propsStream
      .distinctUntilKeyChanged("filters", isEqualData)
      .switchMap((props) =>
        !props.batchGetOrderListCount
          ? Observable.of(Map())
          : props.batchGetOrderListCount(
              props.filters.map((x) => x.setValueMap(baseFilter)),
            ),
      )
      .distinctUntilChanged(isEqualData);

    return propsStream
      .combineLatest(tabCountsStream, (props, tabCounts) => ({
        value: props.value,
        tabs: props.tabs.map((tab, label) =>
          tab.set("count", tabCounts.get(label)),
        ),
      }))
      .distinctUntilChanged(isEqualData)
      .map(
        fp.update("tabs", (tabs) =>
          tabs.reduce((acc, x) => {
            acc.push({
              value: x.get("value"),
              path: x.get("value"),
              label: <TabBadge label={x.get("label")} badge={x.get("count")} />,
            });

            return acc;
          }, []),
        ),
      );
  }),
);

const OrderListTabs = enhancer(Tabs);

OrderListTabs.propTypes = {
  batchGetOrderListCount: PropTypes.func,
  getLocalisationMessage: PropTypes.func,

  basePathname: PropTypes.string.isRequired,
  filter: PropTypes.instanceOf(DataListFilter).isRequired,
};

export default OrderListTabs;
