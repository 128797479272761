import React from "react";
import Immutable, { List, Map, OrderedMap } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, getContext, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { Button, IconButton, MenuItem } from "@material-ui/core";
import { connect } from "react-redux";
import { Link } from "react-router";
import { FilterList } from "@material-ui/icons";
import DataListFilter from "../../helpers/DataListFilter";
import {
  formatDateValuesForDownloadCSV,
  mapOrderListPropsStream,
} from "../../helpers/OrderListHelper";
import { getUser } from "../../reducers/ProfileReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { NEXT_DAY, SAME_DAY, URGENT } from "../../constants/CourierTypes";
import {
  CUSTOMER_ITEM_URL,
  ORDER_LIST_URL,
} from "../../constants/AdminPathConstants";
import { getDynamicFields, getOrderList } from "../../api/admin/AdminOrderApi";
import AdminOrderFilterWrapper from "../../wrappers/admin/AdminOrderFilterWrapper";
import AdminOrderDetailsDialogWrapperV2 from "../../wrappers/admin/AdminOrderDetailsDialogWrapperV2";
import Toggle from "../../components/form/Toggle";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import Redirect from "../../components/router/Redirect";
import FlexBox from "../../components/ui-core/FlexBox";
import MenuItemForm from "../../components/ui-core/MenuItemForm";
import MenuButtonMore from "../../components/ui-core/MenuButtonMore";
import DownloadDynamicCSVDialog from "../../components/csv-core/DownloadDynamicCSVDialog";
import OrderList from "../../components/orders-core/OrderList";
import OrderListTabs from "../../components/orders-core/OrderListTabs";
import OrderAWBManifestPopover from "../../components/orders-core/OrderAWBManifestPopover";
import OrderListErrorNotification from "../../components/orders-core/OrderListErrorNotification";
import { responsive } from "../../../shared/theme/jss-responsive";
import { updateHash, updateQuery } from "../../../shared/helpers/UrlUtils";
import { ROLE_ADMIN_VIEWER } from "../../../shared/constants/Authorities";
import { CREATE_ORDER_POST_CSV_URL } from "../../../shared/constants/FileProxyControllerConstants";
import { hasRole } from "../../helpers/RoleUtils";

const ORDER_FILTER_DIALOG_HASH = "#OFD";
const BATCH_ORDERS_UPDATE_DIALOG_HASH = "#BOUDH";
const BATCH_ORDERS_RESCHEDULE_DIALOG_HASH = "#BORDH";
const DOWNLOAD_DYNAMIC_CSV_DIALOG_HASH = "#DDCDH";

const urgentOrders = Map({
  is_uae: true,
  courier_types: URGENT,
  exclude_customer_ids: null,
});
const nextDayOrders = Map({
  is_uae: true,
  courier_types: NEXT_DAY,
  exclude_customer_ids: [757380, 834984, 11075697].join(","),
});
const sameDayOrders = Map({
  is_uae: true,
  courier_types: SAME_DAY,
  exclude_customer_ids: [757380, 834984].join(","),
});

const searchTypes = OrderedMap()
  .set("all", "Everything")
  .set("order_number", "Order Numbers");

const enhancer = compose(
  useSheet({
    pageFab: { bottom: "64px" },
    appBarRightAction: { height: "100%", paddingBottom: "8px" },
    appBarRightActionToggle: {
      whiteSpace: "nowrap",
      width: "auto",
      marginLeft: "12px",
    },
    filterToggle: { marginTop: "12px" },
    appBarRightActionButton: {
      color: "#fff",
      [responsive("$xs or $sm")]: { display: "none" },
    },
  }),
  getContext({
    setLocationQuery: PropTypes.func.isRequired,
    replaceLocationHash: PropTypes.func.isRequired,
    setLocationQueryFilter: PropTypes.func.isRequired,
  }),
  connect(
    state => {
      const userRoles = getUser(state).get("roles") || [];

      return {
        isAdminViewer: hasRole(userRoles, ROLE_ADMIN_VIEWER),
      };
    },
    { showErrorMessage, showSuccessMessage },
  ),
  mapPropsStream(
    mapOrderListPropsStream(
      getOrderList,
      (
        props,
        filter,
        response,
        onRowSelect,
        selectedItems,
        onRequestRefresh,
        batchGetOrderListCount,
        marketplace,
      ) => ({
        ...props,
        filter,
        onRowSelect,
        selectedItems,
        onRequestRefresh,
        batchGetOrderListCount,
        marketplace,
        list: response.get("list"),
        total: response.get("total"),
        error: response.get("error"),
        isLoading: response.get("isLoading"),
      }),
    ),
  ),
);

AllocationOrderList.propTypes = {
  classes: PropTypes.object,

  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,

  location: PropTypes.object,
  setLocationQuery: PropTypes.func,
  replaceLocationHash: PropTypes.func,
  setLocationQueryFilter: PropTypes.func,

  filter: PropTypes.instanceOf(DataListFilter),
  total: PropTypes.number,
  isLoading: PropTypes.bool,
  list: PropTypes.instanceOf(List),
  error: PropTypes.instanceOf(Error),
  onRequestRefresh: PropTypes.func,

  batchGetOrderListCount: PropTypes.func,

  onRowSelect: PropTypes.func,
  selectedItems: PropTypes.instanceOf(OrderedMap),

  isAdminViewer: PropTypes.bool,
  marketplace: PropTypes.instanceOf(Immutable.Map),
};

function AllocationOrderList(props) {
  const { classes, location } = props;
  const isDomestic = props.filter.getBoolValue("is_uae");
  const useSolr = props.filter.getBoolValue("use_solr");
  const selectedIds = props.selectedItems.keySeq().toArray();
  const csvParams = formatDateValuesForDownloadCSV(props.filter);
  const csvParamsV2 = formatDateValuesForDownloadCSV(
    props.filter.setValue("api_version", "v2"),
  );
  const csvParamsWithNotes = formatDateValuesForDownloadCSV(
    props.filter.setValue("is_note", true),
  );
  const csvDPSParams = formatDateValuesForDownloadCSV(
    props.filter
      .setValue("is_party_screening", true)
      .setValue("api_version", "v1"),
  );

  return (
    <AdminAppLayout
      title={isDomestic ? "Domestic" : "International"}
      appBarRightAction={
        <FlexBox align="center" className={classes.appBarRightAction}>
          <Button
            className={classes.appBarRightActionButton}
            onClick={() =>
              props.setLocationQueryFilter(
                props.filter.setValueMap(urgentOrders),
              )
            }
          >
            Urgent
          </Button>

          <Button
            className={classes.appBarRightActionButton}
            onClick={() =>
              props.setLocationQueryFilter(
                props.filter.setValueMap(sameDayOrders),
              )
            }
          >
            Same Day
          </Button>

          <Button
            className={classes.appBarRightActionButton}
            onClick={() =>
              props.setLocationQueryFilter(
                props.filter.setValueMap(nextDayOrders),
              )
            }
          >
            Next Day
          </Button>

          <Toggle
            accent={true}
            value={!isDomestic}
            label="International"
            className={classes.appBarRightActionToggle}
            onChange={value =>
              props.setLocationQueryFilter(
                props.filter.setValue("is_uae", !value),
              )
            }
          />

          <Toggle
            accent={true}
            label="Fast Search"
            value={useSolr}
            className={classes.appBarRightActionToggle}
            onChange={value =>
              props.setLocationQueryFilter(
                props.filter.setValue("use_solr", value),
              )
            }
          />
        </FlexBox>
      }
    >
      <Redirect
        when={!searchTypes.has(location.query.search_type)}
        to={updateQuery(location, fp.set("search_type", "order_number"))}
      />

      <OrderListErrorNotification
        error={props.error}
        onFixSizeError={() =>
          props.setLocationQueryFilter(props.filter.setPageSize(0))
        }
      />

      <DownloadDynamicCSVDialog
        open={location.hash === DOWNLOAD_DYNAMIC_CSV_DIALOG_HASH}
        getDynamicFields={getDynamicFields}
        createDownloadCSVHref={columns =>
          formatDateValuesForDownloadCSV(
            props.filter.setValue("csv_columns", columns),
          ).toJS()
        }
        onRequestClose={() => props.replaceLocationHash(null)}
      />

      <AdminOrderDetailsDialogWrapperV2
        tab={location.query.view_tab}
        orderId={fp.toFinite(location.query.view_v2)}
        onTabChange={tab => props.setLocationQuery(fp.set("view_tab", tab))}
        onRequestClose={() =>
          props.setLocationQuery(fp.omit(["view_v2", "view_tab"]))
        }
        onEditClick={() =>
          props.setLocationQuery(
            fp.flow(
              fp.unset("view_v2"),
              fp.set("edit_v2", location.query.view_v2),
            ),
          )
        }
        createDriverSuggestHref={() =>
          props.setLocationQuery(fp.flow(fp.set("driver_view", true)))
        }
        location={location}
      />

      <AdminOrderFilterWrapper
        filter={props.filter}
        onFilterChange={filter => {
          props.replaceLocationHash(null);
          props.setLocationQueryFilter(filter);
        }}
        open={location.hash === ORDER_FILTER_DIALOG_HASH}
        onRequestClose={() => props.replaceLocationHash(null)}
      />

      <OrderListTabs
        filter={props.filter}
        basePathname={ORDER_LIST_URL}
        batchGetOrderListCount={props.batchGetOrderListCount}
      />

      <OrderList
        chipTextHint="Search (max orders 200)"
        onRowSelect={props.onRowSelect}
        selectedItems={props.selectedItems}
        maxSearchItems={
          props.filter.getValue("search_type") === "order_number" ? Infinity : 1
        }
        filter={props.filter}
        searchTypes={searchTypes}
        onFilterChange={filter => props.setLocationQueryFilter(filter)}
        list={props.list}
        totalCount={props.total}
        isLoading={props.isLoading}
        createOrderHref={(id, version) =>
          updateQuery(location, fp.set(version === 2 ? "view_v2" : "view", id))
        }
        createCustomerHref={id => CUSTOMER_ITEM_URL + id}
        onStatusClick={item => {
          props.onRowSelect(
            OrderedMap().set(item.get("id"), item.get("order_number")),
          );
          props.replaceLocationHash(BATCH_ORDERS_UPDATE_DIALOG_HASH);
        }}
        onEstimatedDeliveryDateClick={item => {
          props.onRowSelect(
            OrderedMap().set(item.get("id"), item.get("order_number")),
          );
          props.replaceLocationHash(BATCH_ORDERS_RESCHEDULE_DIALOG_HASH);
        }}
        altHeader={
          <FlexBox>
            <FlexBox>
              <OrderAWBManifestPopover
                label="AWB"
                withAWB={true}
                ids={selectedIds}
                withAWBZLabel={true}
                withAWBCompact={true}
              />
            </FlexBox>

            <FlexBox>
              <OrderAWBManifestPopover
                label="Manifest"
                ids={selectedIds}
                withManifestCompact={true}
                withManifestForDriver={true}
                withManifestForCustomer={true}
              />
            </FlexBox>
          </FlexBox>
        }
        cardActionIcons={
          <div>
            <IconButton
              tooltip="Filter Orders"
              containerElement={
                <Link to={updateHash(location, ORDER_FILTER_DIALOG_HASH)} />
              }
            >
              <FilterList />
            </IconButton>

            <MenuButtonMore>
              <MenuItem
                primaryText="Dynamic Download CSV"
                onClick={() =>
                  props.replaceLocationHash(DOWNLOAD_DYNAMIC_CSV_DIALOG_HASH)
                }
              />

              <MenuItemForm
                target="_blank"
                containerElement="a"
                primaryText="Download CSV (OLD)"
                url={CREATE_ORDER_POST_CSV_URL}
                params={csvParams.toJS()}
              />

              <MenuItemForm
                target="_blank"
                containerElement="a"
                primaryText="Download CSV (New)"
                url={CREATE_ORDER_POST_CSV_URL}
                params={csvParamsV2.toJS()}
              />

              <MenuItemForm
                target="_blank"
                containerElement="a"
                primaryText="Download Note CSV"
                url={CREATE_ORDER_POST_CSV_URL}
                params={csvParamsWithNotes.toJS()}
              />

              <MenuItemForm
                target="_blank"
                containerElement="a"
                primaryText="DPS Data CSV"
                url={CREATE_ORDER_POST_CSV_URL}
                params={csvDPSParams.toJS()}
              />
            </MenuButtonMore>
          </div>
        }
      />
    </AdminAppLayout>
  );
}

export default enhancer(AllocationOrderList);
