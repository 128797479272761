import {
  ORDER_LIST_PATH,
  ORDER_TRACK_URL,
  USERS_LIST_PATH,
  ORDER_TRACK_PATH,
  CUSTOMER_ITEM_PATH,
  CUSTOMER_LIST_PATH,
  SUPPLIER_ITEM_PATH,
  SUPPLIER_LIST_PATH,
  ORDER_RULE_ITEM_PATH,
  ORDER_RULE_LIST_PATH,
  ORDER_RULE_CREATE_PATH,
  ORDER_RULES_GROUP_ITEM_PATH,
  ORDER_RULES_GROUP_LIST_PATH,
  COMMUNICATION_SETTINGS_ITEM_PATH,
  COMMUNICATION_SETTINGS_LIST_PATH,
  COMMUNICATION_SETTINGS_CREATE_PATH,
} from "../constants/AdminPathConstants";
import {
  PROFILE_PATH,
  SIGN_IN_PATH,
  SIGN_OUT_PATH,
  DASHBOARD_PATH,
  CHANGE_PASSWORD_PATH,
  RECOVER_PASSWORD_PATH,
} from "../constants/DashboardPathConstants";
import SignOut from "../components/auth/SignOut";
import AdminSignIn from "../containers/admin/AdminSignIn";
import AdminProfile from "../containers/admin/AdminProfile";
import AdminCustomerItem from "../containers/admin/AdminCustomerItem";
import AdminCustomerList from "../containers/admin/AdminCustomerList";
import AdminSupplierItem from "../containers/admin/AdminSupplierItem";
import AdminSupplierList from "../containers/admin/AdminSupplierList";
import AdminUsersListContainer from "../containers/admin/AdminUsersListContainer";
import AdminOrderRuleItemContainer from "../containers/admin/AdminOrderRuleItemContainer";
import AdminOrderRuleListContainer from "../containers/admin/AdminOrderRuleListContainer";
import AdminOrderRuleCreateContainer from "../containers/admin/AdminOrderRuleCreateContainer";
import AdminCommunicationSettingsItem from "../containers/admin/AdminCommunicationSettingsItem";
import AdminCommunicationSettingsList from "../containers/admin/AdminCommunicationSettingsList";
import AdminCommunicationSettingsCreate from "../containers/admin/AdminCommunicationSettingsCreate";
import AdminOrderRulesGroupItemContainer from "../containers/admin/AdminOrderRulesGroupItemContainer";
import AdminOrderRulesGroupListContainer from "../containers/admin/AdminOrderRulesGroupListContainer";
import ToolsRoot from "../containers/tools/ToolsRoot";
import ChangePasswordContainer from "../containers/shared/ChangePasswordContainer";
import RecoverPasswordContainer from "../containers/shared/RecoverPasswordContainer";
import Root from "../components/app-core/Root";
import NotFound from "../components/app-core/NotFound";
import AllocationOrderList from "../containers/allocation/AllocationOrderList";
import AllocationOrderTrackingToolContainer from "../containers/allocation/AllocationOrderTrackingToolContainer";
import { updateQuery } from "../../shared/helpers/UrlUtils";

export default {
  path: "/",
  component: Root,
  indexRoute: {
    onEnter: (state, replace) =>
      replace(updateQuery(ORDER_TRACK_URL, state.location.query)),
  },
  childRoutes: [
    // Auth.

    {
      path: SIGN_OUT_PATH,
      component: SignOut,
    },
    {
      path: RECOVER_PASSWORD_PATH,
      component: RecoverPasswordContainer,
    },
    {
      path: CHANGE_PASSWORD_PATH,
      component: ChangePasswordContainer,
    },
    {
      path: SIGN_IN_PATH,
      component: AdminSignIn,
    },
    {
      path: DASHBOARD_PATH,
      component: ToolsRoot,
      indexRoute: {
        onEnter: (state, replace) =>
          replace(updateQuery(ORDER_TRACK_URL, state.location.query)),
      },
      childRoutes: [
        // Profile.

        {
          path: PROFILE_PATH,
          component: AdminProfile,
        },

        // Orders

        {
          path: ORDER_LIST_PATH,
          component: AllocationOrderList,
        },
        {
          path: ORDER_TRACK_PATH,
          component: AllocationOrderTrackingToolContainer,
        },

        // Rules.

        {
          path: ORDER_RULE_LIST_PATH,
          component: AdminOrderRuleListContainer,
        },
        {
          path: ORDER_RULE_ITEM_PATH,
          component: AdminOrderRuleItemContainer,
        },
        {
          path: ORDER_RULE_CREATE_PATH,
          component: AdminOrderRuleCreateContainer,
        },
        {
          path: ORDER_RULES_GROUP_LIST_PATH,
          component: AdminOrderRulesGroupListContainer,
        },
        {
          path: ORDER_RULES_GROUP_ITEM_PATH,
          component: AdminOrderRulesGroupItemContainer,
        },

        // Customer.

        {
          path: CUSTOMER_LIST_PATH,
          component: AdminCustomerList,
        },
        {
          path: CUSTOMER_ITEM_PATH,
          component: AdminCustomerItem,
        },

        // Supplier.

        {
          path: SUPPLIER_LIST_PATH,
          component: AdminSupplierList,
        },
        {
          path: SUPPLIER_ITEM_PATH,
          component: AdminSupplierItem,
        },

        // Communication Settings

        {
          path: COMMUNICATION_SETTINGS_LIST_PATH,
          component: AdminCommunicationSettingsList,
        },
        {
          path: COMMUNICATION_SETTINGS_CREATE_PATH,
          component: AdminCommunicationSettingsCreate,
        },
        {
          path: COMMUNICATION_SETTINGS_ITEM_PATH,
          component: AdminCommunicationSettingsItem,
        },

        // User.

        {
          path: USERS_LIST_PATH,
          component: AdminUsersListContainer,
        },
      ],
    },

    // Not Found Handler.

    { path: "*", component: NotFound },
  ],
};
